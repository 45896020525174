@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  font-family: -apple-system, 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input[type="radio"]:checked + label {
  border: 2px solid orange;
}

select {
  @apply py-3 w-full px-6 rounded-xl focus:outline-orange-500;
}

li.uncheck:before {
  content: "";
  display: inline-block;
  height: 13px;
  width: 15px;
  position: relative;
  top: 3px;
  margin-right: 8px;
  background-image: url(https://assurance-habitation.selfassurance.fr/devis/application/views/assets/media/uncheck.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

li {
  @apply py-2;
}

li.nocheck:before {
  content: '';
  display: none;
}


li:before {
  content: "";
  display: inline-block;
  height: 13px;
  width: 15px;
  position: relative;
  top: 3px;
  margin-right: 8px;
  background-image: url(https://assurance-habitation.selfassurance.fr/devis/application/views/assets/media/check.svg);
  background-size: contain;
  background-repeat: no-repeat;
}